<template>
  <div v-if="task" class="progress">
    <div class="progress__text">
      <div v-if="task.status" class="status">
        <div v-if="task.status === 'running'" class="status__icon _running icon-spinner">
          <SVGIcon
            :title="'Task Running'"
            :iconName="'upload'"
            :width="'14px'"
            :height="'14px'"
          />
        </div>
        <div v-else-if="task.status === 'complete'" class="status__icon _complete">
          <SVGIcon
            :title="'Task Complete'"
            :iconName="'check_circle'"
            :width="'100%'"
            :height="'100%'"
          />
        </div>
        <div v-else-if="task.status === 'terminated'" class="status__icon _terminated">
          <SVGIcon
            :title="'Task Stopped'"
            :iconName="'stop'"
            :width="'100%'"
            :height="'100%'"
          />
        </div>
        <div v-else-if="task.status === 'error'" class="status__icon _error">
          <SVGIcon
            :title="'Task Error'"
            :iconName="'cancel_filled'"
            :width="'100%'"
            :height="'100%'"
          />
        </div>
      </div>
      <div class="description name">
        <span>{{ task.name ? `${task.name}` : '' }} </span>
      </div>
      <div class="description date">
        <span>{{ task.date ? `${dateParser(task.date)}` : '' }} </span>
      </div>
      <div class="description count">
        <span>{{ task.count }} of {{ task.total }}</span>
      </div>
      <div v-if="startedByUser && task.status !== 'complete' && task.type=== 'upload'" class="ms-2">
        <!-- <IconButton
          :title="'Remove Task'"
          class="cancel-icon"
          :icon="'trash'"
          :width="20"
          :height="20"
          @click.stop="cancel"
        /> -->
        <BaseMenu
          :placement="'right'"
          :appendToBody="true"
          :class="'task-menu'"
          @closed="show_menu = false"
        >
          <IconButton
            v-model="show_menu"
            :icon="'more_vert'"
            :width="20"
            :height="20"
          />
          <template #menu="{closeMenu}">
            <ul>
              <!-- <li v-if="startedByUser">
                <BaseMenuButton @click.stop="handleStopUploading(), closeMenu()">
                  <SVGIcon
                    :iconName="'stop'"
                    :width="'22px'"
                    :height="'22px'"
                  />
                  <span>Stop Uploading</span>
                </BaseMenuButton>
              </li> -->
              <li v-if="startedByUser">
                <BaseMenuButton @click.stop="handleResumeUploading(), closeMenu()">
                  <SVGIcon
                    :iconName="'play_circle'"
                    :width="'22px'"
                    :height="'22px'"
                  />
                  <span>Resume Uploading</span>
                </BaseMenuButton>
              </li>
            </ul>
          </template>
        </BaseMenu>
      </div>
    </div>
    <div class="progress__bar">
      <progress id="autoAnnotate-progress-bar" :value="progress" :max="100" />
    </div>
    <div v-if="task.status === 'error'" class="progress__err-text">
      <span>Error: {{ task.message }}</span>
    </div>
  </div>
  <ResumeUploadImagesModal
    v-if="showResumeUploadModal"
    :task="task"
    @closed="showResumeUploadModal = false"
  />
</template>

<script>
import IconButton from '@/components/IconButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import BaseMenu from '@/components/BaseMenu.vue';
import BaseMenuButton from '@/components/BaseMenuButton.vue';
import ResumeUploadImagesModal from '@/components/DatasetComponent/ResumeUploadImagesModal.vue';

export default {
  name: "UploadStatusBar",
  components: {
    IconButton,
    SVGIcon,
    BaseMenu,
    BaseMenuButton,
    ResumeUploadImagesModal,
  },
  props: {
    task: {
      type: Object,
      default: null,
    },
  },
  emits: ['cancel-task'],
  data() {
    return {
      showResumeUploadModal: false,
    };
  },
  computed: {
    progress() {
      if (this.task.total === 0) {
        return 0;
      } else {
        const result = this.task.count / this.task.total;
        return Number.isNaN(result) ? 0 : result * 100;
      }
    },
    startedByUser() {
      if (this.$store.state.user.user.username === this.task.username) {
        return true;
      }
      return false;
    },
  },
  methods: {
    dateParser(d) {
      const date = new Date(d);
      return `${date.toLocaleString('default', {
        year: 'numeric', month: 'short', day: 'numeric',
      })}`;
    },
    cancel() {
      this.$emit('cancel-task', this.task.id);
      this.$store.commit('tasks/removeUploadTask', { id: this.task.id });
    },
    async handleResumeUploading() {
      this.showResumeUploadModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>

.progress {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__text {
    display: flex;
    width: 100%;
    font-size: 0.9rem;
    font-weight: 600;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 2px;

    @include themify() {
      color: themed('body-text-color-secondary');
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .description {
      display: flex;
      flex-direction: row;
      line-height: 1;
      margin-bottom: 1px;
      white-space: nowrap;

      &.name {
        flex: 1 1 auto;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.2;
        color: var(--body-text-color);
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.date {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.2;
        color: var(--body-text-color-secondary);
      }

      &.count {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.2;
        color: var(--body-text-color-secondary);
        margin-left: 12px;
      }
    }
  }

  &__bar {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1px;

    progress {
      width: 100%;
      border-radius: 0;
      height: 6px;

      &::-webkit-progress-bar {
        @include themify() {
          background-color: themed('progress-bar');
        }
      }
      &::-webkit-progress-value {
        @include themify() {
          background-color: themed('progress-value');
        }
      }
    }
  }

  &__err-text {
    font-size: 0.875rem;
    @include themify() {
      color: var(--color-error);
    }
  }

  &__estimate {
    display: flex;
    font-weight: 600;
    font-size: 0.875rem;
    @include themify() {
      color: themed('body-text-color-secondary');
    }
    margin-top: 5px;
    justify-content: space-between;
  }
}

.status {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 12px;

  &__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    &._running {
      color: var(--color-primary);
    }

    &._complete {
      color: var(--color-success);
    }

    &._error {
      color: var(--color-error);
    }
  }
}

// .cancel-icon {
//   margin-left: 10px;
//   visibility: hidden;
// }

// .progress:hover .cancel-icon {
//   visibility: visible;
// }

// .cancel-icon:hover, .download-icon:hover {
//   @include themify() {
//       color: themed('icon-color-primary');
//   }
// }
</style>
