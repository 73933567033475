<script setup>
import { ref, watch, toRefs } from 'vue';
import { useEventListener, useMouseInElement, useVModel } from '@vueuse/core';

const props = defineProps({
  min: { type: Number, default: 0 },
  max: { type: Number, default: 100 },
  secondary: { type: Number, default: 0 },
  modelValue: { type: Number, required: true },
  step: { type: Number, default: null },
});
const {
  max,
} = toRefs(props);

const emit = defineEmits(['update:modelValue', 'scrubbing-end', 'scrubbing']);

const scrubber = ref();
const scrubbing = ref(false);
const pendingValue = ref(0);

useEventListener('mouseup', () => { if (scrubbing.value) { emit('scrubbing-end'); } scrubbing.value = false; });

const value = useVModel(props, 'modelValue', emit);
const { elementX, elementWidth } = useMouseInElement(scrubber);

watch([scrubbing, elementX], () => {
  const progress = Math.max(0, Math.min(1, (elementX.value) / elementWidth.value));
  if (props.step) {
    pendingValue.value = roundToStep((progress * (props.max - props.min)) + props.min, props.step);
  } else {
    pendingValue.value = (progress * (props.max - props.min)) + props.min;
  }
  if (scrubbing.value && max.value > 0) {
    value.value = pendingValue.value;
    emit('scrubbing', value.value);
  }
});

function emitScrubbing() {
  if (max.value > 0) {
    emit('scrubbing', pendingValue.value); // Emit the initial frame value on mousedown
  }
}

function roundToStep(progress, step) {
  // Ensure step is greater than 0 to avoid division by zero
  if (step <= 0) {
    throw new Error("Step size must be greater than 0");
  }

  // Round the progress value based on the step size
  const roundedValue = Math.round(progress / step) * step;

  return roundedValue;
}

</script>

<template>
  <div ref="scrubber" class="scrubber" @mousedown="scrubbing = true; emitScrubbing()">
    <div v-if="max > 0" class="scrubber-bar">
      <div class="secondary-bar" :style="{ transform: `translateX(${((secondary - min) / (max - min)) * 100 - 100}%)` }" />
      <div class="primary-bar" :style="{ transform: `translateX(${((value - min) / (max - min)) * 100 - 100}%)` }" />
    </div>
    <div v-else class="scrubber-bar" />
    <div class="scrub-time" :class="{ 'show': scrubbing }">
      <slot v-if="max > 0" :pending-value="pendingValue" :position="`${Math.max(0, Math.min(elementX, elementWidth))}px`" />
    </div>
  </div>
</template>

<style lang="scss" scoped>

.scrubber {
  position: relative;
  height: 6px;
  border-radius: 0.25rem;
  cursor: pointer;
  user-select: none;
  background-color: rgba(0,0,0,0.125);
}

/* Styles for the scrubber */
// .scrubber:hover {
//   background-color: rgba(0, 0, 0, 0.5);
// }

/* Styles for the inner containers */
.scrubber-bar {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-radius: 0.25rem;
}

.secondary-bar {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.375);
  z-index: 1;
}

.primary-bar {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--color-primary);
  z-index: 2;
}

.scrub-time {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 999;
}

.scrub-time.show, .scrub-time:hover {
  opacity: 1;
}

</style>
